import { Auth } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js";
// Note this is client-side stripe API, so you cant call things like stripe.customers.retrieve etc
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

const getAuthSessionPayload = async () => {
  try {
    const session = await Auth.currentSession();
    console.log(session);
    if (session.idToken.payload) {
      console.log("I HAVE A FUCKING PAYLOAD");
      return session.idToken.payload;
    }
  } catch (error) {
    console.log(error);
  }
};

function getHostnameWithProtocol() {
  return window.location.origin;
}

export default function Subscribe({ disabled }) {
  // This is the API public key from the test environment
  const stripePublicKey =
    "pk_test_51OrIaqCDSeAskprcutcoD3OsHpZcdttjolDK5WZMDDV1eG9JIlcRiihu31aECXgB5Y7m4z0KES7YuvfyzWxaoSuX00zA07XIdL";

  // Handler for the button click event
  async function handleClick(e) {
    const authPayload = await getAuthSessionPayload();
    const cognitoSub = authPayload.sub;
    console.log("CurrentUser is:" + cognitoSub);
    const cuname = authPayload["cognito:username"];
    console.log("User name is:" + cuname);
    const authPayloadEmail = authPayload.email;
    const clientReferenceId = authPayload.sub;
    const stripe = await loadStripe(stripePublicKey); // Client side stripe API

    const hostUrl = getHostnameWithProtocol();

    console.log("HOST IS", hostUrl);
    const successUrl = hostUrl + "/success?sub_id=" + cognitoSub;
    const cancelUrl = hostUrl + "/cancel?sub_id=" + cognitoSub;
    const { error } = stripe.redirectToCheckout({
      customerEmail: authPayloadEmail,
      clientReferenceId: clientReferenceId,
      lineItems: [
        {
          price: "price_1OwMkPCDSeAskprcxAjEfdWB",
          quantity: 1,
        },
      ],
      mode: "subscription",

      //successUrl:"http://localhost:3000/webhook&session_id={CHECKOUT_SESSION_ID}",
      //cancelUrl: "http://localhost:3000",
      

      successUrl: successUrl + "&session_id={CHECKOUT_SESSION_ID}",
      cancelUrl: cancelUrl + "&session_id={CHECKOUT_SESSION_ID}",
    });

    // Try generating the successurl with the sub (id) and then on the callback, look
    // for sub in the window.location.pathname
    // if you find it, do a match and if all good, reload the cognito data
    // Is thisa hack? Probably but it will work.

    console.log(error);
  }
  return (
    <MenuItem
      disabled={disabled}
      onClick={handleClick}
      // this logic below is not correct for new accounts
      //disabled={NotEligibleToSubscribe()}
      variant="contained"
    >
      <Tooltip title="Subscribe to access full functionality">
        <SubscriptionsIcon />
      </Tooltip>
    </MenuItem>
  );
}
